import './Header.css'

const Header = () => {
    return (
        <div className="header-root">
            <div className="header-logo" style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/dehoga-logo.png)`
            }}/>
            <div className="header-slogan">
                <h1>Ohne Perspektive geben wir den Löffel ab</h1>
                <h3>Eine Aktion für Unternehmer*innen des Gastgewerbes.</h3>
            </div>
        </div>
    )
}

export default Header
