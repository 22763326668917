import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import "./DehogaBlogpost.css"

const Dehogablogpost = () => {
    const tiles = [
        {
            img: "https://dehoga-loeffel-blogpost.s3.eu-central-1.amazonaws.com/loeffel_5.jpg",
            cols: 1,
        },
        {
            img: "https://dehoga-loeffel-blogpost.s3.eu-central-1.amazonaws.com/loeffel_4.jpg",
            cols: 2,
        },
        {
            img: "https://dehoga-loeffel-blogpost.s3.eu-central-1.amazonaws.com/loeffel_7.jpg",
            cols: 2,
        },
        {
            img: "https://dehoga-loeffel-blogpost.s3.eu-central-1.amazonaws.com/loeffel_8.jpg",
            cols: 1,
        },
        {
            img: "https://dehoga-loeffel-blogpost.s3.eu-central-1.amazonaws.com/loeffel_6.jpg",
            cols: 3,
        }
    ]
    return (
        <div className="blogpost-root">
            <div className="blogpost-headline">
                DEHOGA Nordrhein macht mobil: Ohne Perspektive geben wir den Löffel ab!
            </div>
            <GridList cellHeight={240} className="blogpost-imgs" cols={3}>
                {tiles.map((tile, i) => (
                    <GridListTile key={i} cols={tile.cols}>
                        <img src={tile.img} alt="Dehoga" />
                    </GridListTile>
                ))}
            </GridList>
            <div className="blogpost-text">
            <b>Den Druck auf die politisch Verantwortlichen im Bund und in den Ländern will der 
            DEHOGA Nordrhein mit der Aktion "Ohne Perspektive geben wir den Löffel ab" weiter erhöhen.</b><br/><br/>
            Mit Nachdruck werden zwei zentrale Anliegen des DEHOGA Nordrhein und seiner Mitglieder in der Corona-Krise erneuert: 
            Die Forderungen nach einer konkreten Öffnungsperspektive sowie nach einer wirksamen und schnelleren staatlichen Hilfe auch für 
            größere Betriebe und sogenannte verbundene Unternehmen. Hinsichtlich einer dauerhaften und verbindlichen Öffnung der Gastronomie 
            und Hotellerie fordert der DEHOGA NR nachhaltige Strategien und gezielte Maßnahmen unter Anwendung wissenschaftlicher, medizinischer 
            und technischer Erkenntnisse. Dazu zählen flächendeckende und kostenlose Schnelltests, die Nachverfolgung von Infektionsrisiken in öffentlichen 
            Bereichen und der Einsatz von Registrierungs-Apps, die die Arbeit der Gesundheitsämter entlasten. Die Bitte an die Politik lautet dabei, 
            die vom DEHOGA eingereichten Vorschläge und Leitlinien in die Überlegungen mit einfließen zu lassen. Dabei hoffen die Gastronomen auf 
            eine mögliche Öffnung ihrer Betriebe rechtzeitig vor Ostern, die Hoteliers auf die Möglichkeit, neben Geschäftsreisenden auch wieder 
            private Reisende beherbergen zu können.<br /><br/>
            <a href="https://www.dehoga-nordrhein.de/brancheninfos/corona/">Mehr über DEHOGA Nordrhein und Corona erfahren</a>
            </div>
        </div>
    )
}

export default Dehogablogpost
