import "./Footer.css"

const Footer = () => {
    return (
        <div className="footer-root">
            © EsperoTech GmbH &amp; Co. KG 2021. Alle Rechte vorbehalten.
            <div>
                <a target="_blank" href="https://www.dehoga-nordrhein.de/impressum/">Impressum</a> &middot;&nbsp;
                <a target="_blank" href="https://www.dehoga-nordrhein.de/impressum/datenschutzerklaerung/">Datenschutz</a>
            </div>

            <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> &amp; <a href="https://www.flaticon.com/authors/dinosoftlabs”´" title="DinosoftLabs">DinosoftLabs</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
        </div>
    )
}

export default Footer
