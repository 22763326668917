import './PostCarousel.css'
import Carousel from 'react-material-ui-carousel'
import Post from '../post/Post'
import EditPost from '../post/EditPost'

const PostCarousel = ({items, isEdit, handleSubmit, handleCancel, isValidation, moveToValidation, handleNotValidated}) => {
    return (
        <div className="postcarousel-root">
            <Carousel className="postcarousel"
                autoPlay={false}
                animation="slide"
                indicators={false}
                swipe={!isEdit && !isValidation}
                navButtonsAlwaysInvisible={isEdit || isValidation}
            >
                {
                    isEdit ? (
                        <EditPost moveToValidation={moveToValidation}  handleCancel={handleCancel}/>
                    ) : (
                        items.map( (item, i) => <Post key={i} info={item} isValidation={isValidation} handleSubmit={handleSubmit} handleCancel={handleCancel} handleNotValidated={handleNotValidated}/> )
                    )
                }
            </Carousel>
        </div>
    )
}

export default PostCarousel