import { useState, useRef } from "react"
import './Post.css'
import { Paper, Button, Backdrop } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PeopleIcon from '@material-ui/icons/People';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ChevronLeft } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";

const EditPost = ({ moveToValidation, handleCancel }) => {
    const [state, setState] = useState({
        selectedSloganIndex: 0,
        slogan: "HOTELS, öffnen, sicher!",
        file: null,
        imagePreviewUrl: process.env.PUBLIC_URL + "/upload-img-default.png",
        companyName: "",
        creatorName: "",
        companyZip: "",
        companyNumEmployees: 0,
        companyType: "",
        text: "",
        companyLocation: "",
        verificationEmail: "",
        filesizeError: false
    })
    const inputField = useRef(null)

    const invalidateInput = (value, type, nonEmpty) => {
        const isNonEmpty = nonEmpty ? value : true
        const correctType = type === "num" ? !isNaN(value) : true
        return isNonEmpty && correctType
    }

    const validateWithNewImg = async () => {
        if (state.imagePreviewUrl.includes("/upload-img-default.png")) {
            const rnd = Math.floor(Math.random() * Math.floor(4))
            const res = await fetch(process.env.PUBLIC_URL + `/random-img${rnd}.jpg`)
            const blob = await res.blob()
            console.log(rnd)
            console.log(blob)
            let reader = new FileReader()
            reader.onloadend = (e) => {
                moveToValidation({
                    ...state,
                    file: {
                        name: `random-img${rnd}.jpg`,
                        type: "image/jpeg"
                    },
                    imagePreviewUrl: reader.result
                })
            }

            reader.readAsDataURL(blob, `random-img${rnd}.jpg`, {type: "image/jpeg"})
            return
        }
        moveToValidation(state)
    }

    const validateEverything = () => {
        return (
            invalidateInput(state.companyName, "text", true) &&
            invalidateInput(state.creatorName, "text", true) &&
            invalidateInput(state.companyZip, "num", true) &&
            invalidateInput(state.companyNumEmployees, "num", true) &&
            invalidateInput(state.companyType, "text", true) &&
            invalidateInput(state.text, "text", true) &&
            invalidateInput(state.companyLocation, "text", true) &&
            invalidateInput(state.verificationEmail, "text", true)
        )
    }

    const handleCloseFilesizeError = () => {
        setState({
            ...state,
            filesizeError: false
        })
    }

    const slogans = [
        "HOTELS, öffnen, sicher!",
        "GASTRO - Kultur erhalten!",
        "Wir brauchen PERSPEKTIVEN!",
        "Zukunft für GASTGEBER - statt sterben auf Raten!",
        "Betriebserhaltende ENTSCHÄDIGUNG, statt Hilfe!",
        "WISSENSCHAFT und TECHNIK, statt Mittelalter!",
        "Entwickelt STRATEGIEN mit uns!"
    ]

    const changeSloganIndex = (value) => {
        let newIndex = state.selectedSloganIndex + value
        if (newIndex >= slogans.length) {
            newIndex = 0
        }
        if (newIndex < 0) {
            newIndex = slogans.length - 1
        }
        setState({
            ...state,
            selectedSloganIndex: newIndex,
            slogan: slogans[newIndex]
        })
    }

    const handleImageSelected = (e) => {
        e.preventDefault()
        let reader = new FileReader()
        let file = e.target.files[0]
        const filesize = ((file.size / 1024) / 1024).toFixed(4)
        if (filesize > 5.0) {
            setState({
                ...state,
                filesizeError: true
            })
            return
        }
        reader.onloadend = () => {
            setState({
                ...state,
                file: file,
                imagePreviewUrl: reader.result
            })
        }

        reader.readAsDataURL(file)
    }

    return (
        <>
            <div className="post-root">
                <Backdrop style={{ zIndex: 1000 }} open={state.filesizeError} onClick={() => handleCloseFilesizeError()}>
                    <Alert severity="warning" onClick={() => handleCloseFilesizeError()}>Das Ausgewählte Bild ist zu groß. Maximale Größe ist 5MB.</Alert>
                </Backdrop>
                <Paper className="post" elevation={5}>
                    <div className="post-image post-image-edit" style={{
                        backgroundImage: `url(${state.imagePreviewUrl})`,
                        backgroundSize: state.imagePreviewUrl.includes("upload-img-default.png") ? "contain" : "cover"
                    }} onClick={() => {
                        inputField.current.click()
                    }}>
                        <input
                            ref={inputField}
                            className="editpost-upload-image-input"
                            type="file" id="image-upload"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => handleImageSelected(e)}
                        />
                    </div>
                    <div className="post-content-edit">
                        <div className="post-slogan edit-post-slogan">
                            <div className="edit-explanation">
                                <div>
                                    1️⃣ Bild hochladen
                            </div>
                                <div>
                                    2️⃣ Den richtigen Spruch auswählen
                            </div>
                                <div>
                                    3️⃣ Daten angeben
                            </div>
                                <div>
                                    4️⃣ Zur Vorschau gehen
                            </div>
                            </div>
                            <h4>
                                {state.slogan}
                            </h4>
                            <div />
                            <div className="button-area">
                                <Button
                                    style={{
                                        marginRight: "0.625em"
                                    }}
                                    variant="contained" size="small" color="primary" onClick={() => changeSloganIndex(-1)}><ChevronLeftIcon /></Button>
                                <Button variant="contained" size="small" color="primary" onClick={() => changeSloganIndex(1)}><ChevronRightIcon /></Button>
                            </div>
                        </div>
                        <div className="post-info post-info-edit">
                            <div className="info-form">
                                <TextField className="company-name-textfield" style={{ marginRight: "0.625em" }}
                                    id="company-name-input" label="Firmenname"
                                    onChange={(e) => setState({
                                        ...state,
                                        companyName: e.target.value
                                    })}
                                    inputProps={{
                                        maxLength: 30,
                                    }}
                                    error={!invalidateInput(state.companyName, "text", true)}
                                />
                                <TextField className="company-zip-textfield" id="company-zip" style={{ marginRight: "0.625em" }} label="PLZ"
                                    onChange={(e) => setState({
                                        ...state,
                                        companyZip: e.target.value
                                    })}
                                    inputProps={{
                                        maxLength: 5,
                                    }}
                                    error={!invalidateInput(state.companyZip, "num", true)}
                                />
                                <TextField className="company-loc-textfield" id="company-loc" style={{ marginRight: "0.625em" }} label="Ort"
                                    onChange={(e) => setState({
                                        ...state,
                                        companyLocation: e.target.value
                                    })}
                                    inputProps={{
                                        maxLength: 20,
                                    }}
                                    error={!invalidateInput(state.companyLocation, "text", true)}
                                />
                                <Select className="company-type-select" style={{ marginRight: "0.625em" }} id="company-type-input"
                                    onChange={(e) => setState({
                                        ...state,
                                        companyType: e.target.value
                                    })}
                                    displayEmpty
                                    value={state.companyType}
                                    error={!invalidateInput(state.companyType, "text", true)}
                                >
                                    <MenuItem value="" disabled>
                                        Geschäftsfeld
                                </MenuItem>
                                    <MenuItem value="Restaurant">Restaurant</MenuItem>
                                    <MenuItem value="Hotel">Hotel</MenuItem>
                                    <MenuItem value="Andere">Andere</MenuItem>
                                </Select>
                                <TextField className="company-num-textfield" title="Mitarbeitende" style={{ marginRight: "0.625em" }} id="company-num-input" label={<PeopleIcon title="Mitarbeitende" />}
                                    onChange={(e) => setState({
                                        ...state,
                                        companyNumEmployees: e.target.value
                                    })}
                                    type="number"
                                    InputProps={{ inputProps: { min: 0, max: 9999 } }}
                                    error={!invalidateInput(state.companyNumEmployees, "num", true)}
                                />
                                <TextField className="creator-name-textfield" id="creator-name-input" label="Name"
                                    onChange={(e) => setState({
                                        ...state,
                                        creatorName: e.target.value
                                    })}
                                    inputProps={{
                                        maxLength: 35,
                                    }}
                                    error={!invalidateInput(state.creatorName, "text", true)}
                                />
                            </div>
                        </div>
                        <div className="post-text">
                            <TextField
                                className="edit-post-text"
                                id="standard-multiline-flexible"
                                multiline
                                fullWidth
                                rowsMax={3}
                                rows={3}
                                variant="outlined"
                                onChange={(e) => setState({
                                    ...state,
                                    text: e.target.value
                                })}
                                placeholder="Schreiben Sie etwas über Ihre aktuelle Situation..."
                                inputProps={{
                                    maxLength: 150,
                                }}
                                error={!invalidateInput(state.text, "text", true)}
                            />
                            <TextField className="verify-email-textfield" id="verify-email-input" style={{ marginTop: "0.625em", backgroundColor: "white", borderRadius: "0.1875em", width: "100%" }} label="Verifizierungsmail"
                                onChange={(e) => setState({
                                    ...state,
                                    verificationEmail: e.target.value
                                })}
                                error={!invalidateInput(state.verificationEmail, "text", true)}
                                variant="filled"
                                fullwidth
                            />
                        </div>
                    </div>
                    <div className="post-bottom-area">
                        <div className="editpost-send-post">
                            <Button disabled={!validateEverything()} variant="contained" color="primary" onClick={() => validateWithNewImg()}><CheckIcon /></Button>
                            <Button variant="contained" color="primary" onClick={() => handleCancel()}><ClearIcon /></Button>
                        </div>
                    </div>
                </Paper>
            </div>
        </>
    )
}

export default EditPost