import { useState } from "react"
import './Post.css'
import { Paper, Button } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import FacebookIcon from '@material-ui/icons/Facebook';
import MailIcon from '@material-ui/icons/Mail';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const Post = ({ info, isValidation, handleSubmit, handleNotValidated }) => {
    const [state, setState] = useState({
        loading: true
    })

    const emailBody = `Sehr geehrter Leser,%0D%0D` +
        `${info.slogan}%0D%0D` +
        `${info.companyName}%0D` +
        `${info.companyZip}, ${info.companyLocation}%0D%0D` +
        `${info.text}%0D` +
        `Lesen Sie den gesamten Beitrag hier: https://perspektivejetzt.de/${info.uuid}%0D%0D` +
        `Mit freundlichen Grüßen,` +
        `%0D${info.creatorName}%0D%0D`

    const onImageLoad = () => {
        setState({
            ...state,
            loading: false
        })
    }

    const fbUrl = `https://facebook.com/sharer/sharer.php?u=${encodeURI(`https://perspektivejetzt.de/${info.uuid}?sloganId=${info.sloganId}&text=${info.text}`)}`

    return (
        <div className="post-root">
            <Paper className="post" elevation={5}>
                <div className="post-image">
                    <Skeleton className="post-image-skeleton" style={{
                        display: state.loading ? null : "none",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain"
                    }}
                        animation="wave" />
                    <img style={{
                        display: state.loading ? "none" : null
                    }}
                        title={state.name} src={info.imageFileUrl} onLoad={() => onImageLoad()} />
                </div>
                <div className="post-content">
                    <div className="post-slogan">
                        <h1>
                            {info.slogan}
                        </h1>
                    </div>
                    <div className="post-info">
                        <h1>{info.companyName}</h1>
                        <div className="post-info-type">
                            {info.companyType}
                            <div className="post-info-icon">
                                <PeopleIcon />
                                {info.companyNumEmployees} Angestellte
                            </div>
                        </div>
                        <div className="post-info-icon">
                            <LocationOnIcon />
                            {`${info.companyZip}, ${info.companyLocation}`}
                        </div>
                        <div className="post-info-name">
                            Ersteller: {info.creatorName}
                        </div>
                    </div>
                    <div className="post-text">
                        <h1>{info.text}</h1>
                    </div>
                </div>
                <div className="post-bottom-area">
                    <div className="post-share-iconbar">
                        {
                            isValidation ? (
                                <>
                                    <div style={{
                                        color: "#f58220",
                                        paddingRight: "0.3125em"
                                    }}>
                                        Veröffentlichen?
                                        </div>
                                    <CheckIcon className="click-icon" style={{ fontSize: 40 }}
                                        onClick={handleSubmit} />
                                    <ClearIcon className="click-icon" style={{ fontSize: 40 }}
                                        onClick={handleNotValidated} />
                                    <div className="dehoga-icon-bottom" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/dehoga-logo.png)`
                                    }}>
                                    </div>
                                </>
                            ) : (
                                    info.emailNotVerified ? (
                                        <div style={{
                                            textAlign: "center",
                                            color: "#f58220",
                                            padding: "0.3125em"
                                        }}>
                                            📧 Verifizieren Sie Ihre E-Mail, um diesen Post zu teilen.
                                        </div>
                                    ) : (
                                            <>
                                                <a target="popup" href={fbUrl}><FacebookIcon className="click-icon" style={{ fontSize: 40 }} /></a>
                                                <a href={`mailto:?subject=Wir geben den Löffel ab!&body=${emailBody}`}><MailIcon className="click-icon" style={{ fontSize: 40, marginRight: "0.1em" }} /></a>
                                                
                                                <div className="dehoga-icon-bottom" style={{
                                                    backgroundImage: `url(${process.env.PUBLIC_URL}/dehoga-logo.png)`
                                                }}>
                                                </div>
                                            </>
                                        )
                                )
                        }
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default Post