import "./Content.css"

import { useState, useRef } from "react"

import PostCarousel from "../postCarousel/PostCarousel"
import Spoons from "../spoons/Spoons"
import Headspoon from "../headspoon/Headspoon"
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Switch,
    Route
} from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import Dehogablogpost from '../dehogaBlogpost/DehogaBlogpost'


const GET_URL = "https://api.perspektivejetzt.de/businesses"
const POST_URL = "https://api.perspektivejetzt.de/business"

const dummyResp = {
    "allCount": 1,
    "businesses": [
        {
            "companyLocation": "Garching",
            "companyType": "Restaurant",
            "imageFileName": "leguan.jpg",
            "image": "b0235b69-a375-4963-a7cf-aa1bec6b4068/leguan.jpg",
            "companyName": "Augustiner Garching",
            "imageFileType": "image/jpeg",
            "slogan": "Wir geben den Löffel ab!",
            "creatorName": "Jonathan Rösner",
            "uuid": "b0235b69-a375-4963-a7cf-aa1bec6b4068",
            "text": "Blablalbla das ist ein Text der ziemlich langweilig ist.",
            "companyNumEmployees": 12,
            "companyZip": "85748",
            "imageFileUrl": "https://dehoga-loeffel-assets.s3.eu-central-1.amazonaws.com/b0235b69-a375-4963-a7cf-aa1bec6b4068/leguan.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAXQLN7DJP5D7EU2GW%2F20210301%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20210301T153005Z&X-Amz-Expires=900&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHgaDGV1LWNlbnRyYWwtMSJGMEQCIH5OVtHssqsL%2FShuewj7h8UjnA3uO8unxIe4Z51FBmeGAiB2PV8qXa4Ow%2FHgnZDmuQnD5I%2BVTBK1cm3gxqIQuzoEPyr9AQiR%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDUxNjE2MzU3NDM2NyIMpoFr70qHG2WTRjpiKtEBx0YF%2BRF5uC9O39cZ5P56pQ9wwbxE6mMGP5Rs%2BKX88kkuGQsbH1EFjJuzdNHpWuB%2F5j5f05F9tdezT%2BxeM3ePFttexY56x1cvvwR5e7NoYuG54X8wDgtTJnkjydVJQ2wSZu6%2BmKuJuG5Js4yrNUsSZmlPUxuipcIkoYYh7reQbAbof%2BP2esj1jLgY2U1JeW1afICl8kQYd1utQ6Phfgoh5Q4EKqa1iOws6eVHtGy%2FnrgqRXbck5mSuuXQ1kAgkyL77mMjXg9AEUrJrsdvTIqXeJ0w%2B5D0gQY64QFeVd11PpozfFujK%2FwaxDXZKI4yGDdU0N0okFTduR8qUB39bGpbgTzc%2FxFiLjUdETe%2F5CjwVfiX169Amo5bbSxuE8IQn6lHaRZc%2Bkkfk1suZDLfSVxu8vKaCvJH%2Blfi3qlJw%2FeN91ybGrf8mzUPggkT8Ql054RYkQDM6ThCn2IIm5P0qshN%2F%2Ff%2BrtwWPUremATq8LpPbJqG5XnvKwI0YAas6Mdg7gToklA1fYvd%2BLJVGzNXLIeI1uLhM2VFPj6anKxbTcZ8%2Fy9QM8JUEZpW8alD12MehtjG2cgUlVPlU2WY5WE%3D&X-Amz-Signature=0a9bd23a04fa2cac7c50786566c32fbdaa319eec909e798e89d5be496f38a192&X-Amz-SignedHeaders=host"
        }
    ]
}

const Content = () => {
    const [state, setState] = useState({
        isEdit: false,
        isValidation: false,
        spoonGiven: false,
        items: [],
        loading: false,
        error: false,
        isInitDataFetched: false
    })

    const editRef = useRef(null)

    const fetchInitData = async (url) => {
        if (state.isInitDataFetched || state.loading) {
            return
        }
        setState({
            ...state,
            loading: true
        })
        try {
            const resp = await fetch(url)
            const jsonBody = await resp.json()
            const newItems = jsonBody.businesses ? jsonBody.businesses : []
            if (newItems.length !== 0) {
                setState({
                    ...state,
                    loading: false,
                    items: newItems,
                    isInitDataFetched: true
                })
            } else {
                setState({
                    ...state,
                    error: true,
                    isInitDataFetched: true
                })
            }
        } catch (e) {
            setState({
                ...state,
                error: true,
                isInitDataFetched: true
            })
        }
    }

    const onEditPressed = () => {
        setState({
            ...state,
            isEdit: true,
            isValidation: false
        })
    }

    const moveToValidation = (info) => {
        const imageFileName = info.file ? info.file.name : ""
        const imageFileType = info.file ? info.file.type : ""
        const newItems = [
            {
                companyName: info.companyName,
                companyZip: info.companyZip,
                creatorName: info.creatorName,
                companyNumEmployees: info.companyNumEmployees,
                companyType: info.companyType,
                companyLocation: info.companyLocation,
                slogan: info.slogan,
                text: info.text,
                imageFileName: imageFileName,
                imageFileType: imageFileType,
                imageFileUrl: info.imagePreviewUrl,
                emailNotVerified: true,
                verificationEmail: info.verificationEmail,
                sloganId: info.selectedSloganIndex
            },
            ...state.items
        ]
        setState({
            ...state,
            items: newItems,
            isEdit: false,
            isValidation: true
        })
    }

    const handleNotValidated = () => {
        const newItems = [...state.items]
        newItems.shift()
        setState({
            ...state,
            isEdit: false,
            isValidation: false,
            items: newItems
        })
    }

    const handleSubmit = async () => {
        setState({
            ...state,
            loading: true
        })
        try {
            const item = state.items[0]
            const resp = await fetch(POST_URL, {
                method: "POST",
                body: JSON.stringify(item)
            })
            if (resp.status === 200 || resp.status === 201) {
                setState({
                    ...state,
                    isEdit: false,
                    isValidation: false,
                    animateToBottom: true,
                    loading: false,
                    spoonGiven: true
                })
            } else {
                setState({
                    ...state,
                    isEdit: false,
                    isValidation: false,
                    loading: false,
                    error: true
                })
            }
        } catch(e) {
            setState({
                ...state,
                isEdit: false,
                isValidation: false,
                loading: false,
                error: true
            })
        }
        
    }

    const handleCancel = () => {
        setState({
            ...state,
            isEdit: false
        })
    }

    return (
        <div className="content-root">
            <Headspoon animateToBottom={state.animateToBottom === true} />
            <PostCarousel items={state.items} isEdit={state.isEdit} isValidation={state.isValidation} moveToValidation={moveToValidation} handleSubmit={handleSubmit} handleCancel={handleCancel} handleNotValidated={handleNotValidated} />
            <Spoons numberOfSpoons={state.items ? state.items.length : 0} spoonGiven={state.spoonGiven} isEdit={state.isEdit} isValidation={state.isValidation} onEditPressed={() => onEditPressed()} />
            <Dehogablogpost />
            <Switch>
                <Route exact path="/" render={() => {fetchInitData(GET_URL)}}/>
                <Route path="/:id" render={({match}) => {fetchInitData(`${GET_URL}?preferred=${match.params.id}`)}}/>
            </Switch>
            <Backdrop 
                style={{zIndex: 1000}}
            open={state.loading || state.error}>{
                state.error ? (
                    <Alert severity="error">
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <div style={{fontSize: 40}}>🐛</div>
                            Ein Fehler ist aufgetreten, bitte versuchen Sie es später nochmal...
                        </div>
                    </Alert>
                ) : (
                    <CircularProgress style={{zIndex: 10000}} color="primary" />
                )
            }</Backdrop>
        </div>
    )
}

export default Content