import './App.css';
import Header from './components/header/Header'
import Content from './components/content/Content'
import Footer from './components/footer/Footer'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from "react-router-dom"

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#f58220",
      },
      secondary: {
        main: "#004389"
      },
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Header />
          <Content />
          <Footer />
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
