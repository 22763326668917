import './Spoons.css'
import Button from '@material-ui/core/Button'

const Spoons = ({isEdit, onEditPressed, numberOfSpoons, isValidation, spoonGiven}) => {
    numberOfSpoons = numberOfSpoons ? numberOfSpoons : 0;
    const numOfSpoonsToDraw = Math.max(Math.round(Math.log(numberOfSpoons * 5)), 3)
    return (
        <div className="spoons-root" id="spoons-root">
            <div className="spoons">
                <h2>Ohne Perspektive geben {numberOfSpoons} Betriebe den Löffel ab.</h2>
                <div className="spoons-button-area">
                    {
                        (isEdit || isValidation || spoonGiven) ? null : (
                            <Button size="large" style={{zIndex: 200}} variant="contained" onClick={onEditPressed} color="primary">Auch den Löffel abgeben</Button>
                        )
                    }
                </div>
                <img className="bowl" src={process.env.PUBLIC_URL + "/bowl.svg"} alt="bowl" />
                {
                    [...Array(numOfSpoonsToDraw)].map((e, i) => {
                        const deg = -150 * (i / numOfSpoonsToDraw) + 15
                        const transformX = -90 * (i / numOfSpoonsToDraw) - 10
                        const transform = " translate("+transformX+"%, -12%) rotate("+deg+"deg)"
                        const left = -10 * (i / numOfSpoonsToDraw) + 55
                        return <img key={"spoon-" + i} className={"spoon spoon-"+i} src={process.env.PUBLIC_URL + "/spoon.svg"} style={{"transform": transform, "left": left + "%"}} alt="spoon" />
                    })
                }
                
            </div>
        </div>
    )
}

export default Spoons
