import './Headspoon.css'

const Headspoon = ({animateToBottom}) => {
    let transform = "";
    const screenwidth = window.innerWidth / parseFloat(
        getComputedStyle(
          document.querySelector('body')
        )['font-size']
      )
    if(animateToBottom) {
        var offsetTop = document.getElementById("spoons-root").offsetTop;
        if (screenwidth <= 40) {
            offsetTop += 100
        } else {
            offsetTop += 200
        }
        const w = 16

        offsetTop = offsetTop / w

        transform += "translate(0, "+offsetTop+"em) rotate(-90deg)"
    }
    return (
        <div className="headspoon-root">
            <div style={{"transform": transform}}  className="headspoon">
                <img className="spoon" src={process.env.PUBLIC_URL + "/spoon.svg"} alt="spoon" />
            </div>
        </div>
    )
}

export default Headspoon